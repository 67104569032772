import React from "react";
import '../css/loader.css';

const LoadingComponent = ()=>{
    return <div className="loadingParentContainer"><div className="loader"></div><p>Logging in wait a moment</p></div>
}

export default LoadingComponent

export const RegisterLoadingComponent = ()=>{
    return <div className="loadingParentContainer reg"><div className="loader"></div><p>Loading</p></div>
}

export const LogoutLoading = ()=>{
    return  <div className="Logoutloading"><h4>Logging Out, wait moment</h4></div>
}