import Axios from "../api/axios";
const DELETE_PROP = "/api/deleteProp"


const DeleteFunction = async (id) => {
    try {
//    console.log(i);
   const response = await Axios.post(DELETE_PROP,{id});
   console.log(response);
  //  res = response
    } catch (error) {
      console.log(error);
    }
  }

export const DeleteProp = (id)=> {
    
console.log(id);
    DeleteFunction(id)
}