import { BrowserRouter,Route, Routes } from 'react-router-dom';
// import Login from './Login';

// import ResetPwd from './resetPwd';
// import "./index.css";
import "./css/App.css";
import "./css/responsive.css"
import "./css/uploadProp.css"

import Register from './Register';
import Account from '../src/verifyAccountComponent/verify';
// import PropUpload from './propertyUpload';



function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Register/>}/>
      <Route path="/verifyAccount" element={<Account/>}/>
      {/* <Route path="/resetpassword" element={<ResetPwd/>}/> */}
      {/* <Route path="/login" element={<Login/>}/> */}
      {/* <Route path="/propertyUpload" element={<PropUpload/>}/> */}
    </Routes>
    </BrowserRouter>
  //  <main className='App'>
  //   <Register />
  //  </main>
  );
}

export default App;
