import React, { useEffect, useState } from "react";
import Axios from "../api/axios";
import "../css/verify.css";

const VERIFIEDUSER_URL = "/api/verifiedUserPost";

const VerifyAccount = () => {

  const [errMsg, setErrMsg] = useState("");
  const [link, setLink] = useState(null);
  console.log("Hello from verify");

  useEffect(() => {
    const currentUrl = window.location.href;
    const hashPart = currentUrl.split("#")[1];
    verifyVisitedUser(hashPart); // Call the verification function directly here
  }, []);

  const verifyVisitedUser = async (token) => {
    try {
      const response = await Axios.post(VERIFIEDUSER_URL, { Token: token });
      console.log(response);
      setErrMsg(response.data.message);
      setLink(<a href="https://raletale.ng">Log in</a>);
    } catch (error) {
      if (error?.response) {
        console.log(error?.response);
        if (error?.response?.status === 404) {
          setErrMsg(error?.response?.data.error);
          setLink(<a href="https://raletale.ng">Home</a>);
        }
      }
    }
  };

  return (
    <>
      <div className="Verification-Container">
        <p>
          {errMsg} {link}
        </p>
      </div>
    </>
  );
};

export default VerifyAccount;
