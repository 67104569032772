import { useRef, useState, useEffect } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "./api/axios";
import logo from "./logo.jpg";
import images from "./imagecompo"
// import axios from "./api/axios";
import LoadingComponent, { RegisterLoadingComponent, LogoutLoading } from "./loaedingCompo/loading";
import emailjs from 'emailjs-com';
import CryptoJS from 'crypto-js';
// import handleClickForDeleteProperty from "./DeletePropety/handleClickForDeleteProperty"
import { DeleteProp } from "./DeletePropety/handleClickForDeleteProperty";



emailjs.init('AcBnCP-k-O6JagINR');


// const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,24}$/;
const FULLNAME_REGEX = /^[a-zA-Z][a-zA-Z_ ]{0,}$/;
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/;
const PHONE_REGEX = /^[0-9-+]{1,11}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;


const REGSITER_URL = "/api/registerPost";
const LOGIN_URL = "/api/loginPost";
const LOGOUT_URL = "/api/logout"
const UPLOAD_PROPERTY_URL = "/api/uploadProperty";
const SEARCH_PROPERTY_URL = "/api/searchingForProperty"
const AGENT_REGISTER_URL = "/api/agentRegister"
const USER_VERIFICATON = "/api/verifyUserPost"


const Register = () => {
  const userRef = useRef();
  const errRef = useRef();




  const [fullname, setFullName] = useState("")
  const [validFullName, setValidFullName] = useState(false);

  const [fullUserName, setFullUserName] = useState("")

  const [fullFocus, setFullNameFocus] = useState(false);


  const [validAgentName, setValidAgentName] = useState("");

  const [email, setEmail] = useState("")
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [phone, setPhone] = useState("")
  const [validPhone, setValidPhone] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);

  const [location, setLocation] = useState("")



  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [MatchFocus, setMatchFocus] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(null);


  const [availableFor, setAvailableFor] = useState("Available For?");
  const [propertyPurpose, setPropertyPurpose] = useState("Property Purpose");
  const [propertyType, setPropertyType] = useState("Property Type");
  const [noOfBedroom, setNoOfBedroom] = useState("Number of Bedroom")
  const [suites, setSuites] = useState("Number of suites")
  const [story, setStory] = useState("Number of story")
  const [landType, setLandType] = useState("Land type")
  const [landTypeInput, setLandTypeInput] = useState("")
  const [noOfFuelPumps, setNumberOfFuelPumps] = useState("")
  const [warehouseInput, setWarehouseInput] = useState("")
  const [hotelBlahBlah, setHotelBlahBlah] = useState("")

  const [state, setState] = useState("")
  const [LGA, setLGA] = useState("")
  const [nearestBusStop, setNearestBusStop] = useState("")
  const [streetName, setStreetName] = useState("")
  const [buildingNumber, setBuildingNumber] = useState("")
  const [price, setPrice] = useState("")

  const [budgetFrom, setBudgetFrom] = useState("")
  const [budgetTo, setBudgetTo] = useState("")

  const [inspection, setInspection] = useState("")
  const [timeFrom, setTimeFrom] = useState("08:00");
  const [timeTo, setTimeTo] = useState("18:00");

  const [petradioBtn, setPetRadioBtn] = useState("")

  const [gardenAllowedRadioBtn, setGardenAllowedRadioBtn] = useState("")

  useEffect(() => {
    console.log(gardenAllowedRadioBtn);
  }, [gardenAllowedRadioBtn]);

  const [errMsg, setErrMsg] = useState("");
  const [succesMsg, setSuccessMsg] = useState("");

  const [searchErrMsg, setSearchErrMsg] = useState("")
  const [searchSuccessMsg, setSearchSuccessMsg] = useState("")
  const [uploadSuccessMsg, setUploadSuccessMsg] = useState("")

  const [classname, setClassname] = useState(false)


  const [sessionEmail, setSessionEmail] = useState("")

  const [date, setDatte] = useState([])

  // Agent Info

  const [agentFullName, setAgentFullName] = useState();
  const [agentfullFocus, setAgentFullNameFocus] = useState(false);

  const [agentEmail, setAgentEmail] = useState("");
  const [agentEmailFocus, setAgentEmailFocus] = useState(true);
  const [validAgentEmail, setValidAgentEmail] = useState(true);

  const [agentPhone, setAgentPhone] = useState("");
  const [agentPhoneFocus, setAgentPhoneFocus] = useState(true);
  const [validAgentPhone, setValidAgentPhone] = useState(true);

  const [agentWhatsAppPhone, setAgentWhatsAppPhone] = useState("");
  const [agentWhatsAppPhoneFocus, setAgentWhatsAppPhoneFocus] = useState(true);
  const [validAgentWhatsAppPhone, setValidAgentWhatsAppPhone] = useState(true);

  const [agentState, setAgentState] = useState("");
  const [agentLGA, setAgentLGA] = useState("");
  const [agentTown, setAgentTown] = useState("")
  const [agentLandMark, setAgentLandMark] = useState("");
  const [agentStreetAddress, setAgentStreetAddress] = useState("");
  const [agentAltAddressPerson1, setAgentAltAddressPerson1] = useState("")
  const [agentAltAddressPerson2, setAgentAltAddressPerson2] = useState("")

  const [agentErrMsg, setAgentErrMsg] = useState("");



  // Search Contact Info
  const [searchNameContactInfo, setSearchNameContactInfo] = useState("");
  const [searchPhoneCallContactInfo, setSearchPhoneCallContactInfo] = useState("");
  const [searchValidEmailContactInfo, setSearchValidEmailContactInfo] = useState("");
  const [searchWahtsappLineContactInfo, setSearchWahtsappLineContactInfo] = useState("");
  const [searchContactInfoDropdown, setSearchContactInfoDropdown] = useState("");
  const [searchValidEmailContactInfoFocus, setSearchValidEmailContactInfoFocus] = useState(true);
  const [searchValidValidEmailContactInfo, setSearchValidValidEmailContactInfo] = useState(true);







  useEffect(() => {

    const result2 = FULLNAME_REGEX.test(fullname);
    const result3 = EMAIL_REGEX.test(email);
    const result4 = PHONE_REGEX.test(phone);
    const result5 = FULLNAME_REGEX.test(agentFullName);
    const result6 = EMAIL_REGEX.test(agentEmail);
    const result7 = PHONE_REGEX.test(agentPhone);
    const result8 = PHONE_REGEX.test(agentWhatsAppPhone);
    const result9 = EMAIL_REGEX.test(searchValidEmailContactInfo);

    setValidFullName(result2);
    setValidEmail(result3);
    setValidPhone(result4)
    setValidAgentName(result5);
    setValidAgentEmail(result6);
    setValidAgentPhone(result7);
    setValidAgentWhatsAppPhone(result8);
    setSearchValidValidEmailContactInfo(result9);
  }, [fullname, email, phone, agentFullName, agentEmail, agentPhone, agentWhatsAppPhone, searchValidEmailContactInfo]);

  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    // console.log(result);
    // console.log(pwd);
    setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
  }, [pwd, matchPwd]);


  const handleSubmitForRegister = async (e) => {

    e.preventDefault();


    if (!fullname || !email || !phone || !location || !pwd) {
      console.log("Fill the inputs");
    }
    // const v1 = USER_REGEX.test(user);

    else {

      const v2 = PWD_REGEX.test(pwd);
      if (!v2) {
        setErrMsg("Invalid Entry");
        return;
      }
      try {
        const response = await Axios.post(REGSITER_URL,
          {
            fullname, email, phone, location, pwd
          })
        console.log(response);
        console.log("hello");
        setErrMsg(response?.data?.message);
        errTimeOut();
      } catch (err) {
        console.log(err?.response?.status);
        if (err?.response) {
          console.log(err?.response?.data);
          console.log(err?.response);
          if (err?.response?.status === 404) {
            console.log(!err?.response.status);
            console.log(err?.response?.data?.ses);


            setIsLoading(true)

            function encryptObject(object, key) {
              const jsonString = JSON.stringify(object);
              const encryptedData = CryptoJS.AES.encrypt(jsonString, key).toString();
              return encryptedData;
            }



            const stringifyingD = JSON.stringify(err?.response?.data?.ses)

            const myObject = stringifyingD


            const encryptionKey = 'myEncryptionKey';

            const encryptedObject = encryptObject(myObject, encryptionKey);
            let enc = encryptedObject
            const forwardStartIndex = 0;
            const forwardEndIndex = 30;

            const BackwardStartIndex = 100;
            const BackwardEndIndex = enc.length;

            const resultSlicedForward = enc.slice(forwardStartIndex, forwardEndIndex + 1);
            const resultSlicedBackward = enc.slice(BackwardStartIndex, BackwardEndIndex + 1);
            const token = resultSlicedForward + "." + resultSlicedBackward
            try {


              const response = await Axios.post(USER_VERIFICATON,
                {
                  token, encryptedObject
                })
              console.log(response.status);


              emailjs.send("service_p0bgfm4", "template_uejuj3o", {
                from_name: "raletale support",
                to_name: fullname,
                from_email: email,
                message: `<a href="https://raletale.ng/verifyAccount#${token}" style="padding: 0.5em 1em; background-color: blue; text-decoration: none; color: white; border-radius: 4px;">Verify Account</a>`,
                reply_to: "fheufheufeu",
                html: true
              }).then((response) => {
                console.log(response);
                // Handle success (e.g., show a success message)
                console.log('Email sent successfully:', response.status, response.text);
                setSuccessMsg('Verification link has been sent to your Email')
                if (response.status === 200) {
                  setIsLoading(false)
                }
                errTimeOut();
              })
                .catch((error) => {
                  // Handle error (e.g., show an error message)
                  console.error('Email sending failed:', error);
                  setSuccessMsg('Verification link has been sent to your Email')
                });
            } catch (error) {
              console.log("an error occured when trying to", error?.response?.status);
            }



            console.log(err?.response?.data?.message);
          }

        }
        errRef.current.focus();
      }
    }

  };


  const handleSubmitForUpload = async (e) => {
    e.preventDefault()
    console.log(gardenAllowedRadioBtn);
    if (!success || propertyPurpose === "Property Purpose" || availableFor === "Available For?" || propertyType === "Property Type") {
      setErrMsg("Please Fill")
      errTimeOut()
    } else {

      try {
        const response = await Axios.post(UPLOAD_PROPERTY_URL, { sessionEmail, availableFor, propertyPurpose, propertyType, noOfBedroom, suites, story, landType, landTypeInput, noOfFuelPumps, warehouseInput, hotelBlahBlah, state, LGA, nearestBusStop, streetName, buildingNumber, price, budgetFrom, budgetTo, inspection, timeFrom, timeTo, checkboxValues, gardenAllowedRadioBtn })
        console.log(response);
        console.log(response.data.message);
        setErrMsg(response?.data?.message);
        errTimeOut();
      } catch (error) {
        console.log(error?.response);
        if (error?.response?.data?.status === 404) {
          setUploadSuccessMsg(error?.response?.data?.message)
          errTimeOut();
        }
      }
    }

  }

  const handleSubmitForSearch = async (e) => {
    e.preventDefault();
    if (!availableFor || !propertyPurpose || !propertyType || !state || !LGA || !nearestBusStop || !budgetFrom || !budgetTo || !inspection || !timeFrom || !timeTo || !searchNameContactInfo || !searchPhoneCallContactInfo || !searchWahtsappLineContactInfo || !searchValidEmailContactInfo || !searchContactInfoDropdown) {
      setSearchErrMsg("Please provide details")
      errTimeOut();
    } else {

      try {
        const searchformResponse = await Axios.post(SEARCH_PROPERTY_URL, { availableFor, propertyPurpose, propertyType, noOfBedroom, suites, story, landType, landTypeInput, noOfFuelPumps, warehouseInput, hotelBlahBlah, state, LGA, nearestBusStop, budgetFrom, budgetTo, inspection, formatedTimeFromm, formatedTimeTomm, searchNameContactInfo, searchPhoneCallContactInfo, searchValidEmailContactInfo, searchWahtsappLineContactInfo, searchContactInfoDropdown })
        console.log(searchformResponse);
        setSearchSuccessMsg(searchformResponse?.data?.message)
        searchSuccessMsgTimeOut()

      } catch (error) {
        console.log(error);
      }
    }
  }


  const searchSuccessMsgTimeOut = () => {
    setTimeout(() => {
      setSearchSuccessMsg("");
      if (searchSuccessMsg === "") {
        setSearchSuccessMsg("Will you like to request another property, if yes kindly refill the form above and leave the rest for our AI to handle")
      }
    }, 4000);
  };


  const errTimeOut = () => {
    setTimeout(() => {
      setErrMsg("");
      setSearchErrMsg("");
      setSuccessMsg("");
      setUploadSuccessMsg("");
      setSearchSuccessMsg("");
      setAgentErrMsg("")
    }, 4000);

  };

  const handleSubmitForLogin = async (e) => {

    e.preventDefault();
    if (!email || !pwd) {
      setErrMsg("Please provide details");
      errTimeOut();
    } else {
      try {

        const response = await Axios.post(LOGIN_URL,
          { fullname, email, pwd },
        )
        console.log(response);
        if (response.data.fullName) {
          const fullUserName = response.data.fullName;
          setFullUserName(fullUserName)
        }
        if (response?.status === 200) {
          setIsLoading(true)
          setTimeout(() => {
            setIsLoading(false)
            setIsLogoutLoading(false)
            fetchData()
          }, 3000);
        }

      } catch (err) {
        if (!err?.response) {
          console.log(err?.response);
          console.log("No server response")
        } else if (err.response?.status === 404) {
          setErrMsg(err.response?.data.message);
          errTimeOut()
        }
      }
      errRef.current.focus();
    }
  };



  const handleSubmitForAgentLogin = async (e) => {
    e.preventDefault();
    if (!agentFullName || !agentEmail || !agentPhone || !agentWhatsAppPhone || !agentState || !agentLGA || !agentTown || !agentLandMark || !agentStreetAddress || !agentAltAddressPerson1 || !agentAltAddressPerson2) {
      setAgentErrMsg("Please Fill")
      errTimeOut()
    } else {
      try {
        const response = await Axios.post(AGENT_REGISTER_URL, { agentFullName, agentEmail, agentPhone, agentWhatsAppPhone, agentState, agentLGA, agentTown, agentLandMark, agentStreetAddress, agentAltAddressPerson1, agentAltAddressPerson2 })
        console.log(response);
        setAgentErrMsg(response?.data?.message)
        errTimeOut()
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 404) {
          console.log(error?.response?.data?.message);
          setSuccessMsg(error?.response?.data?.message)
          errTimeOut()
        }
      }
    }

    // console.log(agentAltAddressPerson2);
    // console.log("hello");
  }




  const [loggedin, setloggedin] = useState(null)
  const fetchData = async () => {
    try {
      // Fetch login status
      const loginResponse = await Axios.get("https://api.raletale.ng/api/loginPost");

      // https://api.raletale.ng/api/loginPost
      // http://localhost:3002/api/loginPost

      // Check if user is logged in
      const isLoggedIn = loginResponse.data.loggedIn;


      if (isLoggedIn) {

        // If the user is logged in, fetch user data
        setSuccess(isLoggedIn)
        const sessionEmail = loginResponse.data.user[0].Email;
        setSessionEmail(sessionEmail);

        // Update state with user full name
        setFullUserName(loginResponse.data.user[0].fullname);

        try {
          // Fetch user properties
          const propertyResponse = await Axios.post("/api/fetchingProp", { sessionEmail });

          // Update state with user properties
          setDatte(propertyResponse.data.Properties);
        } catch (error) {
          // console.error("Error fetching user properties:", error);
        }
      } else {
        setloggedin(isLoggedIn)
      }
    } catch (error) {
      // console.error("Error fetching login status:", error);
    }
  };
  fetchData()


  const [isLogoutLoading, setIsLogoutLoading] = useState(false)
  const navigateToLogin = async (e) => {
    e.preventDefault();
    const code = 1
    setIsLogoutLoading(true)
    setTimeout(() => {
      setSuccess(loggedin)
    }, 4000);
    try {
      const response = await Axios.post(LOGOUT_URL,
        { code },
      )
      console.log(response)

    } catch (error) {
      console.log(error);
    }
  };



  const handleChangesForAvailableFor = e => {
    setAvailableFor(e.target.value)
  }
  const handleChangesForPropertyPurpose = e => {
    setPropertyPurpose(e.target.value)
  }

  const handleChangesForPropertyType = e => {
    setPropertyType(e.target.value)
  }

  const handleChangesForNumberOfBedroom = e => {
    setNoOfBedroom(e.target.value)
  }

  const handleChangesForNumberOfSuites = e => {
    setSuites(e.target.value);
  }

  const handleChangesForNumberOfStory = e => {
    setStory(e.target.value)
  }

  const handleChangesForLandType = e => {
    setLandType(e.target.value)
  }
  const handleChangesForLandTypeInput = e => {
    setLandTypeInput(e.target.value)
  }

  const handleChangesForNumberofFuelPumps = e => {
    setNumberOfFuelPumps(e.target.value)
  }

  const handleChangesForNumberOfWarehouse = e => {
    setWarehouseInput(e.target.value)
  }
  const handleChangesForHotelblahblah = e => {
    setHotelBlahBlah(e.target.value)
  }

  const handleChangesForState = e => {
    setState(e.target.value)
  }

  const handleChangesForLGA = e => {
    setLGA(e.target.value)
  }

  const handleChangesForNearestBusStop = e => {
    setNearestBusStop(e.target.value)
  }

  const handleChangesForStreetName = e => {
    setStreetName(e.target.value)
  }

  const handleChangesForBuildingNumber = e => {
    setBuildingNumber(e.target.value)
  }

  const handleChangesForPrice = e => {
    setPrice(e.target.value)
  }

  const handleChangesForBudgetFrom = e => {
    setBudgetFrom(e.target.value)
  }

  const handleChangesForBudgetTo = e => {
    setBudgetTo(e.target.value)
  }
  const handleChangesForInspection = e => {
    setInspection(e.target.value)
  }
  const handleChangesForTimeFrom = e => {
    setTimeFrom(e.target.value)
  }
  const handleChangesForTimeTo = e => {
    setTimeTo(e.target.value)
  }

  const [formatedTimeFromm, setFormatedTimeFrom] = useState("");
  const [formatedTimeTomm, setFormatedTimeTo] = useState("");


  // console.log(formatedTimeFromm);
  useEffect(() => {
    // Format timeFrom
    let timePartFrom = timeFrom.split(':');
    let hourFrom = parseInt(timePartFrom[0], 10);
    let minuteFrom = parseInt(timePartFrom[1], 10);
    let period = 'AM';
    if (hourFrom >= 12) {
      period = 'PM';
      if (hourFrom > 12) {
        hourFrom -= 12;
      }
    }
    const formatedTimeFrom = `${hourFrom.toString().padStart(2, '0')}:${minuteFrom.toString().padStart(2, '0')} ${period}`;
    setFormatedTimeFrom(formatedTimeFrom);

    // Format timeTo
    let timePartTo = timeTo.split(':');
    let hourTo = parseInt(timePartTo[0], 10);
    let minuteTo = parseInt(timePartTo[1], 10);
    let periodTo = 'AM';
    if (hourTo >= 12) {
      periodTo = 'PM';
      if (hourTo > 12) {
        hourTo -= 12;
      }
    }
    let formatedTimeTo = `${hourTo.toString().padStart(2, '0')}:${minuteTo.toString().padStart(2, '0')} ${periodTo}`;
    setFormatedTimeTo(formatedTimeTo);
  }, [timeFrom, timeTo]);

  const handleChangesForPetRadioBtn = e => {
    const value = e.target.value;
    setPetRadioBtn(value);
    // console.log(value);
    if (value === "no") {
      setCheckboxValues([])
    }
  }


  // Searching Contact Info
  const handleChangesForSearchNameContactInfo = e => {
    setSearchNameContactInfo(e.target.value)
  }
  const handleChangesForSearchPhoneCallContactInfo = e => {
    setSearchPhoneCallContactInfo(e.target.value)
  }
  const handleChangesForSearchValidEmailContactInfo = e => {
    setSearchValidEmailContactInfo(e.target.value);
  }
  const handleChangesForSearchWhatsappLineContactInfo = e => {
    setSearchWahtsappLineContactInfo(e.target.value);
  }
  const handleChangesForSearchContactInfoDropdown = e => {
    setSearchContactInfoDropdown(e.target.value);
  }




  const navigatingForms = () => {
    setClassname(current => !current)
    console.log(classname);
  }





  const [checkboxValues, setCheckboxValues] = useState([]);



  const hanleChangesForCheckBox = (e) => {
    const { value, checked } = e.target;


    if (checked) {
      setCheckboxValues((prevValues) => [...prevValues, value])
    } else {
      setCheckboxValues((prevValues) => prevValues.filter((val) => val !== value));
      console.log(checkboxValues);
    }
  }

  const [deletedItems, setDeletedItems] = useState([]);

  const handleDelete = (id) => {
    // Add the item to the deletedItems array
    setDeletedItems((prevDeletedItems) => [...prevDeletedItems, id]);
    DeleteProp(id);
  };


  return (
    <>
      {success ? (
        <>
          <nav>
            <img className="logoImg" src={logo} alt="hell" />
          </nav>

          <section className="headerMessage">
            <p >Simplistic property solution <br /> powered by Artificial Intelligence(AI)</p>
          </section><br />
          <h2 style={{ color: "#fff", textAlign: "center" }}>Hi {fullUserName}, welcome to your dashboard</h2>
          <section className="uploadprop">
            <div className="g">

              <h1>Upload  a Property</h1>
              <form onSubmit={handleSubmitForUpload}>

                <div className="upload-property-section">
                  <div className="column1-after-loggedin">
                    <select onChange={handleChangesForAvailableFor} value={availableFor}>
                      <option value="Available For?">Available For?</option>
                      <option value="Buy">Buy</option>
                      <option value="Rent">Rent</option>
                      <option value="Lease">Lease</option>
                    </select>
                    <select className="propertyPurpose" onChange={handleChangesForPropertyPurpose} value={propertyPurpose}>
                      <option value="Property Purpose">Property Purpose</option>
                      <option value="Commercial">Commercial</option>
                      <option value="Residential">Residential</option>
                    </select>

                    <div className={propertyPurpose === "Commercial" ? "show" : "hide"}>
                      <div>
                        <select onChange={handleChangesForPropertyType} value={propertyType}>
                          <option value="Property Type">Property Type</option>
                          <option value="Flat">Flat</option>
                          <option value="Duplex">Duplex</option>
                          <option value="Shopping Complex">Shopping Complex</option>
                          <option value="Bungalow">Bungalow</option>
                          <option value="Story Building">Story Building</option>
                          <option value="Land">Land</option>
                          <option value="Filling Station">Filling Station</option>
                          <option value="Warehouse">Warehouse</option>
                          <option value="Hotel/Motel/Guest House">Hotel/Motel/Guest House</option>
                        </select>

                        <div className={propertyType === "Flat" || propertyType === "Duplex" || propertyType === "Bungalow" ? "show" : "hide"}>
                          <select onChange={handleChangesForNumberOfBedroom} value={noOfBedroom}>
                            <option value="Number of Bedroom">Number of Bedroom</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="others">others</option>
                          </select>

                        </div>
                        <div className={propertyType === "Shopping Complex" ? "show" : "hide"}>
                          <select onChange={handleChangesForNumberOfSuites} value={suites}>
                            <option value="Number of suites">Number of suites</option>
                            <option value="1-10">1-20</option>
                            <option value="21-40">21-40</option>
                            <option value="41-60">41-60</option>
                            <option value="61-100">61-100</option>
                            <option value="100+">100+</option>
                          </select>

                        </div>
                        <div className={propertyType === "Story Building" ? "show" : "hide"}>
                          <select onChange={handleChangesForNumberOfStory} value={story}>
                            <option value="Number of story">Number of story</option>
                            <option value="1-20">1-20</option>
                            <option value="21-40">21-40</option>
                            <option value="41-60">41-60</option>
                            <option value="661-100">61-100</option>
                            <option value="100+">100+</option>
                          </select>
                        </div>
                        <div className={propertyType === "Land" ? "show landFlex-after-loggedin" : "hide"}>
                          <select onChange={handleChangesForLandType} value={landType}>
                            <option value="Land type">Land type</option>
                            <option value="Plot">Plot</option>
                            <option value="Acres">Acres</option>
                            <option value="Hectres">Hectres</option>
                          </select>

                          <div className={landType === "Plot" || landType === "Acres" || landType === "Hectres" ? "show" : "hide"}>
                            <input onChange={handleChangesForLandTypeInput} value={landTypeInput} type="number" placeholder="No of plot/acres/hectres" />
                          </div>
                        </div>
                        <div className={propertyType === "Filling Station" ? "show landFlex-after-loggedin" : "hide"}>
                          <label>How many fuel pumps?</label>
                          <input onChange={handleChangesForNumberofFuelPumps} value={noOfFuelPumps} type="number" placeholder="Enter number" />
                        </div>
                        <div className={propertyType === "Warehouse" ? "show landFlex-after-loggedin" : "hide"}>
                          <input onChange={handleChangesForNumberOfWarehouse} value={warehouseInput} type="number" placeholder="Size(square metre)" />
                        </div>
                        <div className={propertyType === "Hotel/Motel/Guest House" ? "show landFlex-after-loggedin" : "hide"}>
                          <select onChange={handleChangesForHotelblahblah} value={hotelBlahBlah}>
                            <option value="Number of rooms">Number of rooms</option>
                            <option value="1-10">1-10</option>
                            <option value="10-20">10-20</option>
                            <option value="20-30">20-30</option>
                            <option value="30-40">30-40</option>
                            <option value="40-50">40-50</option>
                            <option value="50+">50+</option>
                          </select>
                        </div>

                      </div>
                    </div>
                    <div className={propertyPurpose === "Residential" ? "show" : "hide"}>
                      <div>
                        <select onChange={handleChangesForPropertyType} value={propertyType}>
                          <option value="Property Type">Property Type</option>
                          <option value="Flat">Flat</option>
                          <option value="Duplex">Duplex</option>
                          <option value="Shopping Complex">Shopping Complex</option>
                          <option value="Bungalow">Bungalow</option>
                          <option value="Story Building">Story Building</option>
                          <option value="Land">Land</option>
                        </select>
                        <div className={propertyType === "Flat" || propertyType === "Duplex" || propertyType === "Bungalow" ? "show" : "hide"}>
                          <select onChange={handleChangesForNumberOfBedroom} value={noOfBedroom}>
                            <option value="Number of Bedroom">Number of Bedroom</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="others">others</option>
                          </select>
                        </div>
                        <div className={propertyType === "Shopping Complex" ? "show" : "hide"}>
                          <select onChange={handleChangesForNumberOfSuites} value={suites}>
                            <option value="Number of suites">Number of suites</option>
                            <option value="1-10">1-20</option>
                            <option value="21-40">21-40</option>
                            <option value="41-60">41-60</option>
                            <option value="61-100">61-100</option>
                            <option value="100+">100+</option>
                          </select>
                        </div>
                        <div className={propertyType === "Story Building" ? "show" : "hide"}>
                          <select onChange={handleChangesForNumberOfStory} value={story}>
                            <option value="Number of story">Number of story</option>
                            <option value="1-20">1-20</option>
                            <option value="21-40">21-40</option>
                            <option value="41-60">41-60</option>
                            <option value="661-100">61-100</option>
                            <option value="100+">100+</option>
                          </select>
                        </div>
                        <div className={propertyType === "Land" ? "show landFlex-after-loggedin" : "hide"}>
                          <select onChange={handleChangesForLandType} value={landType}>
                            <option value="Land type">Land type</option>
                            <option value="Plot">Plot</option>
                            <option value="Acres">Acres</option>
                            <option value="Hectres">Hectres</option>
                          </select>

                          <div className={landType === "Plot" || landType === "Acres" || landType === "Hectres" ? "show" : "hide"}>
                            <input onChange={handleChangesForLandTypeInput} value={landTypeInput} type="number" placeholder="No of plot/acres/hectres" />
                          </div>
                        </div>
                        <div className={propertyType === "Filling Station" ? "show landFlex-after-loggedin" : "hide"}>
                          <label>How many fuel pumps?</label><br />
                          <input onChange={handleChangesForNumberofFuelPumps} value={noOfFuelPumps} type="number" placeholder="Enter number" />
                        </div>
                        <div className={propertyType === "Warehouse" ? "show landFlex-after-loggedin" : "hide"}>
                          <input onChange={handleChangesForNumberOfWarehouse} value={warehouseInput} type="number" placeholder="Size(square metre)" />
                        </div>
                        <div className={propertyType === "Hotel/Motel/Guest House" ? "show landFlex-after-loggedin" : "hide"}>
                          <select onChange={handleChangesForHotelblahblah} value={hotelBlahBlah}>
                            <option value="Number of rooms">Number of rooms</option>
                            <option value="1-10">1-10</option>
                            <option value="10-20">10-20</option>
                            <option value="20-30">20-30</option>
                            <option value="30-40">30-40</option>
                            <option value="40-50">40-50</option>
                            <option value="50+">50+</option>
                          </select>
                        </div>

                      </div>

                    </div>
                    <input onChange={handleChangesForState} value={state} type="text" placeholder="State" />
                    <input onChange={handleChangesForLGA} value={LGA} type="text" placeholder="Local Government Area" />
                    <input onChange={handleChangesForNearestBusStop} value={nearestBusStop} type="text" placeholder="Land mark/Nearest Bus stop" />
                    <input onChange={handleChangesForStreetName} value={streetName} type="text" placeholder="Street name" />
                    <input onChange={handleChangesForBuildingNumber} value={buildingNumber} type="text" placeholder="Building Number/ Plot no" />
                    <input onChange={handleChangesForPrice} value={price} type="number" placeholder="(₦) Price" />

                    <div align="left">
                      <p style={{ margin: 5 + "px" }}>Available for inspection</p>
                      <input onChange={handleChangesForInspection} value={inspection} type="date" placeholder="Enter days available for inspection" />
                    </div>
                    <div className="time-container-after-loggedin">
                      <p>Time:</p>
                      <p>From</p>
                      <input onChange={handleChangesForTimeFrom} value={timeFrom} type="time" placeholder="From" />
                      <p>To</p>
                      <input onChange={handleChangesForTimeTo} value={timeTo} type="time" placeholder="" />
                    </div>
                  </div>
                  <div className="extras-container-after-loggedin">
                    <h1>Extras</h1>
                    <div className="pets-container-after-loggedin">
                      <p className="">Pets Allowed:</p>
                      <div className="yes-no-container-after-loggedin">
                        <p>Yes</p>
                        <input onChange={handleChangesForPetRadioBtn} type="radio" value="yes" name="petQuerry" />
                      </div>
                      <div className="yes-no-container-after-loggedin">
                        <p>No</p>
                        <input onClick={handleChangesForPetRadioBtn} type="radio" value="no" name="petQuerry" />
                      </div>
                    </div>
                    <div className={petradioBtn === "yes" ? "show petinput" : "hide"}>
                      <div className="PetCheckContainer">
                        <input type="checkbox" name="Pet1" checked={petradioBtn === "no" ? false : checkboxValues.includes("Dog")} onChange={hanleChangesForCheckBox} value="Dog" placeholder="Pet types" />
                        <p>Dog</p>
                      </div>
                      <div className="PetCheckContainer">
                        <input type="checkbox" name="Pet1" checked={petradioBtn === "no" ? false : checkboxValues.includes("Cat")} onChange={hanleChangesForCheckBox} value="Cat" placeholder="Pet types" />
                        <p>Cat</p>
                      </div>
                      <div className="PetCheckContainer">
                        <input type="checkbox" name="Pet1" checked={petradioBtn === "no" ? false : checkboxValues.includes("Bird")} onChange={hanleChangesForCheckBox} value="Bird" placeholder="Pet types" />
                        <p>Bird</p>
                      </div>
                      <div className="PetCheckContainer">
                        <input type="checkbox" name="Pet1" checked={petradioBtn === "no" ? false : checkboxValues.includes("Others")} onChange={hanleChangesForCheckBox} value="Others" placeholder="Pet types" />
                        <p>Others</p>
                      </div>
                    </div>
                    <div className="garden-container-after-loggedin">
                      <p>Garden Allowed:</p>
                      <div className="yes-no-container-after-loggedin">
                        <p>Yes</p>
                        <input type="radio" value="Yes" name="GardenQuerry" onChange={(e) => {
                          setGardenAllowedRadioBtn(e.target.value);
                        }} />
                      </div>
                      <div className="yes-no-container-after-loggedin">
                        <p>No</p>
                        <input type="radio" value="no" name="GardenQuerry" onChange={(e) => {
                          setGardenAllowedRadioBtn(e.target.value);
                        }} />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <button className="submitBtn">submit</button>
                <button className="logOutBtn" onClick={navigateToLogin}>log out</button>
                <br />

                <p
                  style={{ margin: 0 + " auto" }}
                  ref={errRef}
                  className={errMsg ? "errmsg" : "offscreen"}
                  arial-live="assertive"
                >
                  {errMsg}
                </p>

              </form>
              <p className={uploadSuccessMsg ? "search-success-msg" : "offscreen"}>{uploadSuccessMsg}</p>
            </div>
            <div className="historySection">
              <h1>History</h1>
              <section className="theBoxesParent">
                {
                  date.length ? date.map((an) => (
                     

                    // <>

                    <div key={an.id} className={`theboxes ${deletedItems.includes(an.id) ? "deleted" : ""}`}>
                      {/* {setResultId(an.id)} */}
                      {/* <div> */}
                        <p><span>Date&Time:</span> {an.Date}</p>
                      {/* </div> */}
                      <p><span>State: </span>{an.state}</p>
                      <p><span>Purpose: </span> {an.property_purpose}</p>
                      <p><span>Type: </span> {an.property_type}</p>
                      <p><span>LGA: </span> {an.LGA}</p>
                      <p><span>Price: </span> {an.price}</p>
                      <p><span style={{ color: 'orange' }}>In progress</span></p>
                      <div className="theboxes overlay">
                        <div
                          className="deleteConntainer"
                          onClick={() => handleDelete(an.id)}><FontAwesomeIcon icon={faTrash} /></div>
                      </div>
                    </div>
                    // </>


                    // <li>{an.Date}</li>
                  )) : <p>Empty</p>
                }
              </section>
            </div>
            {isLogoutLoading ? <LogoutLoading /> : <p></p>}
          </section>
          <footer>
            <p>Copyright &copy; 2023 RaleTale, All rights reserved</p>
          </footer>
        </>
      ) :

        (
          <>

            {/* Logo */}
            <nav>
              <img className="logoImg" src="https://drive.google.com/uc?id=1zIF5rR-uqkyvIVjzEtup38Y5Sv5J3Vzl" alt="hell" />
            </nav>

            {/* Heading Message */}
            <section className="headerMessage">
              <p >Simplistic property solution <br /> powered by Artificial Intelligence(AI)</p>
            </section><br />


            {/* Body Content Section */}
            <section className="bodyContent">

              {/*Body Content First Column section */}
              <div className="BodyContentColumn AppfirstColumn">
                <div className="requestPropertySection">
                  <div className="headingTexts">
                    <h3>In need of property?</h3>
                    <p>Fill this form to make a request</p>
                  </div>
                  <form id="searchform" onSubmit={handleSubmitForSearch}>

                    <div className="search-property-section">
                      <div className="column1">
                        <div className="col-a">
                          <div className="col-for-available-for">
                            <select onChange={handleChangesForAvailableFor} value={availableFor}>
                              <option value="Available For?">I need property to?</option>
                              <option value="Buy">Buy</option>
                              <option value="Rent">Rent</option>
                              <option value="Lease">Lease</option>
                            </select>
                          </div>

                          <div className="col-for-property-purpose">
                            <select className="propertyPurpose" onChange={handleChangesForPropertyPurpose} value={propertyPurpose}>
                              <option value="Property Purpose">Property Purpose</option>
                              <option value="Commercial">Commercial</option>
                              <option value="Residential">Residential</option>
                            </select>
                            <div className={propertyPurpose === "Commercial" ? "show" : "hide"}>
                              <div className="col-for-comercial-pick">
                                <select onChange={handleChangesForPropertyType} value={propertyType}>
                                  <option value="Property Type">Property Type</option>
                                  <option value="Flat">Flat</option>
                                  <option value="Duplex">Duplex</option>
                                  <option value="Shopping Complex">Shopping Complex</option>
                                  <option value="Bungalow">Bungalow</option>
                                  <option value="Story Building">Story Building</option>
                                  <option value="Land">Land</option>
                                  <option value="Filling Station">Filling Station</option>
                                  <option value="Warehouse">Warehouse</option>
                                  <option value="Hotel/Motel/Guest House">Hotel/Motel/Guest House</option>
                                </select>

                                <div className={propertyType === "Flat" || propertyType === "Duplex" || propertyType === "Bungalow" ? "show" : "hide"}>
                                  <select onChange={handleChangesForNumberOfBedroom} value={noOfBedroom}>
                                    <option value="Number of Bedroom">Number of Bedroom</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="others">others</option>
                                  </select>

                                </div>
                                <div className={propertyType === "Shopping Complex" ? "show" : "hide"}>
                                  <select onChange={handleChangesForNumberOfSuites} value={suites}>
                                    <option value="Number of suites">Number of suites</option>
                                    <option value="1-10">1-20</option>
                                    <option value="21-40">21-40</option>
                                    <option value="41-60">41-60</option>
                                    <option value="61-100">61-100</option>
                                    <option value="100+">100+</option>
                                  </select>

                                </div>
                                <div className={propertyType === "Story Building" ? "show" : "hide"}>
                                  <select onChange={handleChangesForNumberOfStory} value={story}>
                                    <option value="Number of story">Number of story</option>
                                    <option value="1-20">1-20</option>
                                    <option value="21-40">21-40</option>
                                    <option value="41-60">41-60</option>
                                    <option value="661-100">61-100</option>
                                    <option value="100+">100+</option>
                                  </select>
                                </div>
                                <div className={propertyType === "Land" ? "show landFlex" : "hide"}>
                                  <select onChange={handleChangesForLandType} value={landType}>
                                    <option value="Land type">Land type</option>
                                    <option value="Plot">Plot</option>
                                    <option value="Acres">Acres</option>
                                    <option value="Hectres">Hectres</option>
                                  </select>

                                  <div className={landType === "Plot" || landType === "Acres" || landType === "Hectres" ? "show" : "hide"}>
                                    <input onChange={handleChangesForLandTypeInput} value={landTypeInput} type="number" placeholder="No of plot/acres/hectres" />
                                  </div>
                                </div>
                                <div className={propertyType === "Filling Station" ? "show landFlex" : "hide"}>
                                  <label>How many fuel pumps?</label>
                                  <input onChange={handleChangesForNumberofFuelPumps} value={noOfFuelPumps} type="number" placeholder="Enter number" />
                                </div>
                                <div className={propertyType === "Warehouse" ? "show landFlex" : "hide"}>
                                  <input onChange={handleChangesForNumberOfWarehouse} value={warehouseInput} type="number" placeholder="Size(square metre)" />
                                </div>
                                <div className={propertyType === "Hotel/Motel/Guest House" ? "show landFlex" : "hide"}>
                                  <select onChange={handleChangesForHotelblahblah} value={hotelBlahBlah}>
                                    <option value="Number of rooms">Number of rooms</option>
                                    <option value="1-10">1-10</option>
                                    <option value="10-20">10-20</option>
                                    <option value="20-30">20-30</option>
                                    <option value="30-40">30-40</option>
                                    <option value="40-50">40-50</option>
                                    <option value="50+">50+</option>
                                  </select>
                                </div>

                              </div>
                            </div>
                            <div className={propertyPurpose === "Residential" ? "show" : "hide"}>
                              <div>
                                <select onChange={handleChangesForPropertyType} value={propertyType}>
                                  <option value="Property Type">Property Type</option>
                                  <option value="Flat">Flat</option>
                                  <option value="Duplex">Duplex</option>
                                  <option value="Shopping Complex">Shopping Complex</option>
                                  <option value="Bungalow">Bungalow</option>
                                  <option value="Story Building">Story Building</option>
                                  <option value="Land">Land</option>
                                </select>
                                <div className={propertyType === "Flat" || propertyType === "Duplex" || propertyType === "Bungalow" ? "show" : "hide"}>
                                  <select onChange={handleChangesForNumberOfBedroom} value={noOfBedroom}>
                                    <option value="Number of Bedroom">Number of Bedroom</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="others">others</option>
                                  </select>
                                </div>
                                <div className={propertyType === "Shopping Complex" ? "show" : "hide"}>
                                  <select onChange={handleChangesForNumberOfSuites} value={suites}>
                                    <option value="Number of suites">Number of suites</option>
                                    <option value="1-10">1-20</option>
                                    <option value="21-40">21-40</option>
                                    <option value="41-60">41-60</option>
                                    <option value="61-100">61-100</option>
                                    <option value="100+">100+</option>
                                  </select>
                                </div>
                                <div className={propertyType === "Story Building" ? "show" : "hide"}>
                                  <select onChange={handleChangesForNumberOfStory} value={story}>
                                    <option value="Number of story">Number of story</option>
                                    <option value="1-20">1-20</option>
                                    <option value="21-40">21-40</option>
                                    <option value="41-60">41-60</option>
                                    <option value="661-100">61-100</option>
                                    <option value="100+">100+</option>
                                  </select>
                                </div>
                                <div className={propertyType === "Land" ? "show landFlex" : "hide"}>
                                  <select onChange={handleChangesForLandType} value={landType}>
                                    <option value="Land type">Land type</option>
                                    <option value="Plot">Plot</option>
                                    <option value="Acres">Acres</option>
                                    <option value="Hectres">Hectres</option>
                                  </select>

                                  <div className={landType === "Plot" || landType === "Acres" || landType === "Hectres" ? "show" : "hide"}>
                                    <input onChange={handleChangesForLandTypeInput} value={landTypeInput} type="number" placeholder="No of plot/acres/hectres" />
                                  </div>
                                </div>
                                <div className={propertyType === "Filling Station" ? "show landFlex" : "hide"}>
                                  <label>How many fuel pumps?</label><br />
                                  <input onChange={handleChangesForNumberofFuelPumps} value={noOfFuelPumps} type="number" placeholder="Enter number" />
                                </div>
                                <div className={propertyType === "Warehouse" ? "show landFlex" : "hide"}>
                                  <input onChange={handleChangesForNumberOfWarehouse} value={warehouseInput} type="number" placeholder="Size(square metre)" />
                                </div>
                                <div className={propertyType === "Hotel/Motel/Guest House" ? "show landFlex" : "hide"}>
                                  <select onChange={handleChangesForHotelblahblah} value={hotelBlahBlah}>
                                    <option value="Number of rooms">Number of rooms</option>
                                    <option value="1-10">1-10</option>
                                    <option value="10-20">10-20</option>
                                    <option value="20-30">20-30</option>
                                    <option value="30-40">30-40</option>
                                    <option value="40-50">40-50</option>
                                    <option value="50+">50+</option>
                                  </select>
                                </div>

                              </div>

                            </div >
                          </div>
                        </div>

                        <div className="col-a LGA-State-Cont">

                          <input onChange={handleChangesForLGA} value={LGA} type="text" placeholder="Local Government Area" />
                          <input onChange={handleChangesForState} value={state} type="text" placeholder="State" />
                        </div>

                        <div className="col-a gh">
                          <div className="nearest-bus">
                            <input onChange={handleChangesForNearestBusStop} value={nearestBusStop} type="text" placeholder="Land mark / Prefered Neighborhood" />
                            <div className="budget-container">
                              <label>Budget:</label>
                              <input onChange={handleChangesForBudgetFrom} value={budgetFrom} type="number" placeholder="₦ From" />
                              <input onChange={handleChangesForBudgetTo} value={budgetTo} type="number" placeholder="₦ To" />
                            </div>
                          </div>
                          <div className="availableForInspection">
                            <p>I am available for inspection on:</p>
                            <input onChange={handleChangesForInspection} value={inspection} type="date" placeholder="Enter days available for inspection" />
                            <div className="time-container">
                              <p>Time:</p>
                              {/* <p>From</p> */}
                              <input onChange={handleChangesForTimeFrom} value={timeFrom} type="time" />
                              <p>To</p>
                              <input onChange={handleChangesForTimeTo} value={timeTo} type="time" />
                            </div>
                          </div>

                          {/* </div> */}
                        </div>

                        <div>  <h4>Contact Info</h4></div><br />
                        <div className="col-a LGA-State-Cont">
                          <input onChange={handleChangesForSearchNameContactInfo} value={searchNameContactInfo} type="text" placeholder="Name" />
                          <input onChange={handleChangesForSearchPhoneCallContactInfo} value={searchPhoneCallContactInfo} type="text" placeholder="Phone Call Line" />
                        </div>
                        <div className="col-a LGA-State-Cont">
                          <div className="wer">
                            {/*  */}
                            <input onChange={handleChangesForSearchValidEmailContactInfo} value={searchValidEmailContactInfo}
                              aria-describedby="ContactValidEmailidnote"
                              aria-invalid={searchValidValidEmailContactInfo ? "false" : "true"}
                              onFocus={() => setSearchValidEmailContactInfoFocus(true)} onBlur={() => setSearchValidEmailContactInfoFocus(false)} type="email" placeholder="Valid Email" />
                            {/*  */}
                            <p id="ContactValidEmailidnote" className={searchValidEmailContactInfo && searchValidEmailContactInfoFocus && !searchValidValidEmailContactInfo ? "instruction ValidEmailContactInfo" : "offscreen"}>Make sure you Enter a valid Email</p>
                            {/* className={searchValidEmailContactInfoFocus ? "instruction" : "offscreen"} */}
                          </div>
                          <div className="wer">   <input onChange={handleChangesForSearchWhatsappLineContactInfo} value={searchWahtsappLineContactInfo} type="text" placeholder="WhatsApp Line" /></div>
                        </div>
                        <div className="contact-you-dropdown">
                          <label>How should we contact you? </label>
                          <select onChange={handleChangesForSearchContactInfoDropdown} value={searchContactInfoDropdown}>
                            <option value=""></option>
                            <option value="Phone call">Phone call</option>
                            <option value="SMS">SMS</option>
                            <option value="Phone call or SMS">Phone call and SMS</option>
                            <option value="Email">Email</option>
                            <option value="Whatsapp">Whatsapp</option>
                            <option value="All of the above">All of the above</option>
                          </select>
                          {/* <input onChange={handleChangesForLGA} value={LGA} type="" placeholder="How should we contact you" /> */}
                        </div>




                      </div>

                    </div>
                    <br />
                    <button className="submitBtn">submit request</button>
                    {/* <p>{propertyFoundMsg}</p> */}
                    <p className={searchSuccessMsg ? "search-success-msg" : "offscreen"}>{searchSuccessMsg}</p>
                    <p
                      ref={errRef}
                      className={searchErrMsg ? "errmsg" : "offscreen"}
                      arial-live="assertive"
                    >
                      {searchErrMsg}
                    </p>
                    {/* <button onClick={navigateToLogin}>log out</button> */}

                  </form>
                </div>



                {/* Other Content Section */}
                <div className="divSamples">
                  <img src={images.rent} alt="hello" />
                  {/* <img src={ob.l} alt="hello" /> */}
                  <h1>Find your choice property within 24hours</h1>
                  <p>Our AI runs your request through our robust property database and gives us a feedback within minutes. We then double check the availability of the property with the owner and connect you within 24hrs.<br /><br /> We charge a token of ₦2000 or its equivalent in your local currency to connect you for physical inspection of the property.</p>
                </div>
              </div>


              <div className="BodyContentColumn section2">
                <div className="signInAndSignUpSection">

                  <div className={classname ? "hide" : "show col1"}>
                    <div className="headingTexts">
                      <h3>Do you have property to sell or lease out?</h3>
                      <p>Login or Register to upload your property</p>
                    </div><br />

                    <form id="signIn" onSubmit={handleSubmitForLogin}>
                      <h1>Sign In</h1>
                      <p
                        ref={errRef}
                        className={errMsg ? "errmsg" : "offscreen"}
                        arial-live="assertive"
                      >
                        {errMsg}
                      </p>
                      {/* <div className="inputContainer"> */}
                      <input
                        type="email"
                        id="email"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        // value={email}
                        placeholder="Email"
                      />
                      {/* </div><br /> */}
                      {/* <div className="inputContainer"> */}
                      <input
                        type="password"
                        id="password"
                        onChange={(e) => {
                          setPwd(e.target.value);
                        }}
                        value={pwd}
                        placeholder="Password"
                      />
                      {/* </div> */}

                      {/*Form Buttons Section*/}
                      <div className="formButtonsSection">
                        <button className="signInBtn">Sign In</button>
                        <div className="needAnAccountSection">
                          <p>
                            Don't have an account?
                          </p>
                          <span>
                            <button type="button" className="signUpBtn" onClick={navigatingForms}>Sign Up</button>
                          </span>
                        </div>
                        {isLoading ? <LoadingComponent /> : <p></p>}
                      </div>
                    </form>
                  </div>


                  {/* Register Section */}

                  <div className={classname ? "show col1" : "hide"}>

                    <div className="headingTexts">
                      <h3>Have property to sell or lease out?</h3>
                      <p>Upload it here</p>
                    </div>
                    <form id="register" onSubmit={handleSubmitForRegister}>
                      <h1>Register</h1>
                      <p
                        ref={errRef}
                        className={errMsg ? "errmsg" : "offscreen"}
                        aria-live="assertive"
                      >
                        {errMsg}
                      </p>
                      <p
                        ref={errRef}
                        className={succesMsg ? "successmsg" : "offscreen"}
                        aria-live="assertive"
                      >
                        {succesMsg}
                      </p>
                      <div className="col-row">
                        <div className="inputContainer">
                          <div className="validandInvalidContainer">
                            {/* <div className="in">
                              <span className={validFullName ? "valid" : "hide"}>
                                <FontAwesomeIcon icon={faCheck} />
                              </span>
                              <span className={validFullName || !fullname ? "hide" : "invalid"}>
                                <FontAwesomeIcon icon={faTimes} />
                              </span>
                            </div> */}

                            <input
                              type="text"
                              id="fullname"
                              ref={userRef}
                              autoComplete="off"
                              onChange={(e) => {
                                setFullName(e.target.value);
                              }}
                              required
                              aria-invalid={validFullName ? "false" : "true"}
                              aria-describedby="fullidnote"
                              onFocus={() => {
                                setFullNameFocus(true);
                              }}
                              onBlur={() => {
                                setFullNameFocus(false);
                              }}
                              placeholder="Full name"
                            />
                          </div>
                          <p
                            id="fullidnote"
                            className={
                              fullFocus && fullname && !validFullName ? "instructions" : "offscreen"
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            &nbsp;

                            Must contain only letters(lowercase and uppercase).

                            numbers, underscores, hyphens not allowed

                            e.g JOHN PAUL.
                          </p>
                        </div>

                        <div className="inputContainer">
                          <div className="validandInvalidContainer">
                            <span className={validEmail ? "valid" : "hide"}>
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span className={validEmail || !email ? "hide" : "invalid"}>
                              <FontAwesomeIcon icon={faTimes} />
                            </span>

                            <input
                              type="email"
                              id="email"
                              ref={userRef}
                              autoComplete="off"
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              required
                              aria-invalid={validEmail ? "false" : "true"}
                              aria-describedby="emailidnote"
                              onFocus={() => {
                                setEmailFocus(true);
                              }}
                              onBlur={() => {
                                setEmailFocus(false);
                              }}
                              placeholder="Email"
                            />
                          </div>
                          <p
                            id="emailidnote"
                            className={
                              emailFocus && email && !validEmail ? "instructions" : "offscreen"
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            &nbsp; Must be a valid Email address.
                            <br />
                            example@gmail.com
                            <br />
                          </p>
                        </div>

                      </div>
                      <div className="col-row">
                        <div className="inputContainer">
                          <div className="validandInvalidContainer">
                            <span className={validPhone ? "valid" : "hide"}>
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span className={validPhone || !phone ? "hide" : "invalid"}>
                              <FontAwesomeIcon icon={faTimes} />
                            </span>

                            <input
                              type="tel"
                              id="phone"
                              ref={userRef}
                              autoComplete="off"
                              onChange={(e) => {
                                setPhone(e.target.value);
                              }}
                              required
                              aria-invalid={validPhone ? "false" : "true"}
                              aria-describedby="phoneidnote"
                              onFocus={() => {
                                setPhoneFocus(true);
                              }}
                              onBlur={() => {
                                setPhoneFocus(false);
                              }}
                              placeholder="Telephone"
                            />
                          </div>
                          <p
                            id="phoneidnote"
                            className={
                              phoneFocus && phone && !validPhone ? "instructions" : "offscreen"
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            &nbsp; Must not contain letters.
                            Must be a valid number
                            <br />
                            e.g 08100000000
                            <br />
                          </p>
                        </div>
                        <div className="inputContainer">

                          <input
                            type="text"
                            id="location"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => {
                              setLocation(e.target.value);
                            }}
                            required
                            aria-invalid={validPhone ? "false" : "true"}
                            aria-describedby="locationidnote"
                            placeholder="Address (Office ,Home or any address)"
                          />
                        </div>
                      </div>

                      <div className="col-row">
                        <div className="inputContainer">
                          <div className="validandInvalidContainer">
                            <span className={validPwd ? "valid ptp" : "hide"}>
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span className={validPwd || !pwd ? "hide" : "invalid"}>
                              <FontAwesomeIcon icon={faTimes} />
                            </span>


                            <input
                              type="password"
                              id="password"
                              onChange={(e) => {
                                setPwd(e.target.value);
                              }}
                              required
                              aria-invalid={validPwd ? "false" : "true"}
                              aria-describedby="pwdnote"
                              onFocus={() => {
                                setPwdFocus(true);
                              }}
                              onBlur={() => {
                                setPwdFocus(false);
                              }}
                              placeholder="Password"
                            />
                          </div>
                          <p
                            id="pwdnote"
                            className={pwdFocus && !validPwd ? "instructions" : "offscreen"}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            &nbsp;8 to 24 characters.
                            <br />
                            Must include uppercase and lowercase, a number and a
                            special character.
                            <br />
                            Allowed special characters:(!,@,#,$,%)
                          </p>
                        </div>
                        <div className="inputContainer">
                          <div className="validandInvalidContainer">
                            <span className={validMatch && matchPwd ? "valid" : "hide"}>
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span className={validMatch || !matchPwd ? "hide" : "invalid"}>
                              <FontAwesomeIcon icon={faTimes} />
                            </span>

                            <input
                              type="password"
                              id="confirm_pwd"
                              ref={userRef}
                              onChange={(e) => {
                                setMatchPwd(e.target.value);
                              }}
                              required
                              aria-invalid={validMatch ? "false" : "true"}
                              aria-describedby="confirmnote"
                              onFocus={() => {
                                setMatchFocus(true);
                              }}
                              onBlur={() => {
                                setMatchFocus(false);
                              }}
                              placeholder="Confirm Password"
                            />
                          </div>
                          <p
                            id="confirmnote"
                            className={
                              MatchFocus && !validMatch ? "instructions" : "offscreen"
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            &nbsp; Must match the first password input field.
                          </p>
                        </div>
                      </div>
                      <div className="formButtonsSection">


                        {/* <div> */}
                        <button
                          // onClick={rem/}
                          className="signUpBtn"
                          disabled={!validFullName || !validPwd || !validMatch ? true : false}
                        >
                          Sign up
                        </button>
                        <div className="needAnAccountSection">
                          <p>Already have an account?</p>
                          <button type="button" className="signInBtn" onClick={navigatingForms}>
                            Sign In
                          </button>
                        </div>
                        {/* <LoadingComponent /> */}
                        {isLoading ? <RegisterLoadingComponent /> : <p></p>}
                      </div>
                      {/* </div> */}
                    </form>

                  </div>
                </div>
                {/* Agent Form Section */}
                <div className="otherContent signin-other">
                  <div>
                    <img src={images.rent5} className="first-image" alt="hello" />
                    {/* <img src={images.rent6} alt="hello" /> */}
                  </div>
                  {/* <h1>Find your home with the people you trust</h1>  
                  <p>Only raletale.ng connects you directly to the listing agent who knows the home best. No cold calls, robocalls, or spam from random agents. Search, connect, collaborate, and share on your terms.</p>   */}
                </div>
              </div>
              <div className="BodyContentColumn section3">
                <div className="show col2">
                  <div className="headingTexts">
                    <h3>Apply to be our field agent</h3>
                    <p>Get 20% for every successful transaction</p>
                  </div>
                  <form id="agent" onSubmit={handleSubmitForAgentLogin}>
                    <div className="col-row">
                      <div className="inputContainer">
                        <div className="validandInvalidContainer">
                          <span className={validAgentName ? "valid" : "hide"}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span className={validAgentName || !agentFullName ? "hide" : "invalid"}>
                            <FontAwesomeIcon icon={faTimes} />
                          </span>

                          <input
                            type="text"
                            id="agentFullName"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => {
                              setAgentFullName(e.target.value)
                            }}
                            // required
                            aria-invalid={validAgentName ? "false" : "true"}
                            aria-describedby="agentfullnameidnote"
                            onFocus={() => {
                              setAgentFullNameFocus(true);
                            }}
                            onBlur={() => {
                              setAgentFullNameFocus(false);
                            }}
                            placeholder="Full Name"
                          />
                        </div>
                        <p
                          id="agentfullnameidnote"
                          className={
                            agentfullFocus && agentFullName && !validAgentName ? "instructions" : "offscreen"
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          &nbsp;

                          Must contain only letters(lowercase and uppercase).
                          <br />
                          numbers, underscores, hyphens not allowed
                          <br />
                          e.g JOHN PAUL.
                        </p>
                      </div>
                      <div className="inputContainer">
                        <div className="validandInvalidContainer">
                          <span className={validAgentEmail ? "valid" : "hide"}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span className={validAgentEmail || !agentEmail ? "hide" : "invalid"}>
                            <FontAwesomeIcon icon={faTimes} />
                          </span>

                          <input
                            type="email"
                            id="agentemail"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => {
                              setAgentEmail(e.target.value);
                            }}
                            // required
                            aria-invalid={validAgentEmail ? "false" : "true"}
                            aria-describedby="agentemailidnote"
                            onFocus={() => {
                              setAgentEmailFocus(true);
                            }}
                            onBlur={() => {
                              setAgentEmailFocus(false);
                            }}
                            placeholder="Email"
                          />
                        </div>
                        <p
                          id="agentemailidnote"
                          className={
                            agentEmailFocus && agentEmail && !validAgentEmail ? "instructions" : "offscreen"
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          &nbsp; Must be a valid Email address.
                          <br />
                          example@gmail.com
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="col-row">
                      <div className="inputContainer">
                        <div className="validandInvalidContainer">
                          <span className={validAgentPhone ? "valid" : "hide"}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span className={validAgentPhone || !agentPhone ? "hide" : "invalid"}>
                            <FontAwesomeIcon icon={faTimes} />
                          </span>

                          <input
                            type="tel"
                            id="agentphone"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => {
                              setAgentPhone(e.target.value);
                            }}
                            // required
                            aria-invalid={validAgentPhone ? "false" : "true"}
                            aria-describedby="agentphoneidnote"
                            onFocus={() => {
                              setAgentPhoneFocus(true);
                            }}
                            onBlur={() => {
                              setAgentPhoneFocus(false);
                            }}
                            placeholder="Telephone"
                          />
                        </div>
                        <p
                          id="agentphoneidnote"
                          className={
                            agentPhoneFocus && agentPhone && !validAgentPhone ? "instructions" : "offscreen"
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          &nbsp; Must not contain letters.
                          Must be a valid number
                          <br />
                          e.g 08100000000
                          <br />
                        </p>
                      </div>
                      <div className="inputContainer">
                        <div className="validandInvalidContainer">
                          <span className={validAgentWhatsAppPhone ? "valid" : "hide"}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span className={validAgentWhatsAppPhone || !agentWhatsAppPhone ? "hide" : "invalid"}>
                            <FontAwesomeIcon icon={faTimes} />
                          </span>

                          <input
                            type="tel"
                            id="whatsappphone"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => {
                              setAgentWhatsAppPhone(e.target.value);
                            }}
                            // required
                            aria-invalid={validAgentWhatsAppPhone ? "false" : "true"}
                            aria-describedby="WhatsAppphoneidnote"
                            onFocus={() => {
                              setAgentWhatsAppPhoneFocus(true);
                            }}
                            onBlur={() => {
                              setAgentWhatsAppPhoneFocus(false);
                            }}
                            placeholder="Whatsapp No"
                          />
                        </div>
                        <p
                          id="WhatsAppphoneidnote"
                          className={
                            agentWhatsAppPhoneFocus && agentWhatsAppPhone && !validAgentWhatsAppPhone ? "instructions" : "offscreen"
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          &nbsp; Must not contain letters.
                          Must be a valid number
                          <br />
                          e.g 08100000000
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="contact-info-label">
                      <label>Contact Info</label>
                    </div>
                    <div className="col-row">
                      <div className="inputContainer">
                        <div className="validandInvalidContainer">
                          <span className={validPwd ? "valid ptp" : "hide"}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span className={validPwd || !pwd ? "hide" : "invalid"}>
                            <FontAwesomeIcon icon={faTimes} />
                          </span>


                          <input
                            type="text"
                            // id="AgentState"
                            onChange={(e) => {
                              setAgentState(e.target.value);
                            }}
                            // required
                            // aria-invalid={validPwd ? "false" : "true"}
                            // aria-describedby="pwdnote"
                            // onFocus={() => {
                            //   setPwdFocus(true);
                            // }}
                            // onBlur={() => {
                            //   setPwdFocus(false);
                            // }}
                            placeholder="State"
                          />
                        </div>

                      </div>
                      <div className="inputContainer">
                        <div className="validandInvalidContainer">
                          <span className={validMatch && matchPwd ? "valid" : "hide"}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span className={validMatch || !matchPwd ? "hide" : "invalid"}>
                            <FontAwesomeIcon icon={faTimes} />
                          </span>

                          <input
                            type="text"
                            // id="confirm_pwd"
                            ref={userRef}
                            onChange={(e) => {
                              setAgentLGA(e.target.value);
                            }}
                            // required
                            // aria-invalid={validMatch ? "false" : "true"}
                            // aria-describedby="confirmnote"
                            // onFocus={() => {
                            //   setMatchFocus(true);
                            // }}
                            // onBlur={() => {
                            //   setMatchFocus(false);
                            // }}
                            placeholder="Local Government Area"
                          />
                        </div>
                        {/* <p
                          id="confirmnote"
                          className={
                            MatchFocus && !validMatch ? "instructions" : "offscreen"
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          &nbsp; Must match the first password input field.
                        </p> */}
                      </div>
                    </div>
                    <div className="col-row">
                      <div className="inputContainer">
                        <div className="validandInvalidContainer">
                          <span className={validPwd ? "valid ptp" : "hide"}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span className={validPwd || !pwd ? "hide" : "invalid"}>
                            <FontAwesomeIcon icon={faTimes} />
                          </span>


                          <input
                            type="text"
                            onChange={(e) => {
                              setAgentTown(e.target.value);
                            }}
                            placeholder="Town"
                          />
                        </div>

                      </div>
                      <div className="inputContainer">
                        <div className="validandInvalidContainer">
                          <span className={validMatch && matchPwd ? "valid" : "hide"}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span className={validMatch || !matchPwd ? "hide" : "invalid"}>
                            <FontAwesomeIcon icon={faTimes} />
                          </span>

                          <input
                            type="text"

                            onChange={(e) => {
                              setAgentLandMark(e.target.value);
                            }}
                            // required

                            placeholder="Land Mark"
                          />
                        </div>

                      </div>
                    </div>
                    <div className="col-row">
                      <div className="inputContainer">
                        <div className="validandInvalidContainer">
                          <span className={validMatch && matchPwd ? "valid" : "hide"}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span className={validMatch || !matchPwd ? "hide" : "invalid"}>
                            <FontAwesomeIcon icon={faTimes} />
                          </span>

                          <input
                            type="text"

                            onChange={(e) => {
                              setAgentStreetAddress(e.target.value);
                            }}
                            // required
                            placeholder="Street Address"
                          />
                        </div>
                      </div>
                      <div className="inputContainer">
                        <div className="validandInvalidContainer">

                          <input
                            type="text"

                            onChange={(e) => {
                              setAgentAltAddressPerson1(e.target.value);
                            }}

                            placeholder="Alternate Contact Person 1"
                          />
                        </div>

                      </div>
                    </div>
                    <div className="col-row" id="last">
                      <div className="inputContainer">
                        <div className="validandInvalidContainer">

                          <input
                            type="text"

                            onChange={(e) => {
                              setAgentAltAddressPerson2(e.target.value);
                            }}
                            placeholder="Alternate Contact Person 2"
                          />
                        </div>
                      </div>
                    </div>
                    <p
                      ref={errRef}
                      className={agentErrMsg ? "errmsg" : "offscreen"}
                      arial-live="assertive"
                    >
                      {agentErrMsg}
                    </p>
                    <p
                      ref={errRef}
                      className={succesMsg ? "successmsg" : "offscreen"}
                      aria-live="assertive"
                    >{succesMsg}</p>

                    <div className="agentApplyNowBtnSection">

                      <button type="submit">
                        Apply now
                      </button>
                      {/* </span> */}
                    </div>

                  </form>
                </div>
                <div className="otherContent withAgent">
                  <div>
                    <img className="first-image" src={images.rent4} alt="hello" />
                    <img src={images.rent3} alt="hello" />
                  </div>
                  <div className="withAgentTextCont">
                    <h1>Apply to be an agent now</h1>
                    <p>Whether you're a first-time buyer or an experienced investor, a real estate agent's expertise is invaluable in making informed decisions and achieving your real estate goals. Trustworthy and client-focused, they strive to provide exceptional service and exceed expectations.</p>
                  </div>
                </div>
              </div>
            </section>
            <footer>
              <p>Copyright &copy; 2023 RaleTale, All rights reserved</p>
            </footer>
          </>
        )
      }
    </>
  );

};

export default Register;
