import rent from "./images/rent.jpg"
import rent2 from "./images/rent3.jpg"
import rent3 from "./images/rent2.jpg"
import rent4 from "./images/rent4.jpg"
import rent5 from "./images/rent5.jpg"
import rent6 from "./images/rent6.png"

const ob={
    rent,
    rent2,
    rent3,
    rent4,
    rent5,
    rent6
}
export default ob